import {ModalBase} from "../modal_base";

export class TcoMisMilCommentAddBulkNonePaginated extends ModalBase {
    constructor() {
        super('tco_mis_mil_comment_add_bulk_none_paginated');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        window.dispatchEvent(new Event('RebuildAutocompletes'));
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const noteText = this.MODAL_CONTAINER_EL.find('#comment_bulk').val();
            if(!noteText || noteText.trim().length === 0) {
                this.MODAL_CONTAINER_EL.find('#comment_bulk').addClass('error');
                return;
            }

            this.MODAL_CONTAINER_EL.find('#comment_bulk').removeClass('error');
            TachoMissingMileage_bulk_none_paginated_comment($modalTriggerBtn, this.MODAL_CONTAINER_EL);
        });
    }
}